import { RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { SironaHttpInterceptor } from '@shared/common/http/sironaHttpInterceptor';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.CodeServiceProxy,
        ApiServiceProxies.ConfigServiceProxy,
        ApiServiceProxies.MedRegServiceProxy,
        ApiServiceProxies.NaregServiceProxy,
        ApiServiceProxies.PsyRegServiceProxy,
        ApiServiceProxies.AddressAutocompleteServiceProxy,
        ApiServiceProxies.CantonSpecificsServiceProxy,
        ApiServiceProxies.PeopleApplicationServiceProxy,
        ApiServiceProxies.LiaTypeServiceProxy,
        ApiServiceProxies.LiaPersonServiceProxy,
        ApiServiceProxies.LiaContactServiceProxy,
        ApiServiceProxies.LiaRestrictionsServiceProxy,
        ApiServiceProxies.LiaRestrictionBServiceProxy,
        ApiServiceProxies.LiaInsuranceServiceProxy,
        ApiServiceProxies.LiaEmploymentsServiceProxy,
        ApiServiceProxies.LiaInvoiceAddressServiceProxy,
        ApiServiceProxies.LiaDocumentServiceProxy,
        ApiServiceProxies.LiaLanguageSkillServiceProxy,
        ApiServiceProxies.LiaLanguageSkillBServiceProxy,
        ApiServiceProxies.LiaAcademicTitleServiceProxy,
        ApiServiceProxies.LiaEmploymentCServiceProxy,
        ApiServiceProxies.LiaEmploymentBServiceProxy,
        ApiServiceProxies.LiaPreviousEmploymentServiceProxy,
        ApiServiceProxies.LiaGuaranteeServiceProxy,
        ApiServiceProxies.LiaPostgraduateTitlePrivateLawServiceProxy,
        ApiServiceProxies.LiaPostgraduateTitleServiceProxy,
        ApiServiceProxies.LiaLicenceInAnotherCantonServiceProxy,
        ApiServiceProxies.LiaDiplomaServiceProxy,
        ApiServiceProxies.LiaVaccinationServiceProxy,
        ApiServiceProxies.EhcServiceProxy,
        ApiServiceProxies.EhcSelfEmploymentServiceProxy,
        ApiServiceProxies.EhcRegisterServiceProxy,
        ApiServiceProxies.EhcEducationServiceProxy,
        ApiServiceProxies.EhcMedicalFieldServiceProxy,
        ApiServiceProxies.EhcLanguageSkillServiceProxy,
        ApiServiceProxies.EhcPracticeServiceProxy,
        ApiServiceProxies.EhcPracticeConfigurationServiceProxy,
        ApiServiceProxies.EhcElectronicPatientRecordServiceProxy,
        ApiServiceProxies.EhcQualityRequirementServiceProxy,
        ApiServiceProxies.EhcContactServiceProxy,
        ApiServiceProxies.EhcEmploymentServiceProxy,
        ApiServiceProxies.EhcInvoiceAddressServiceProxy,
        ApiServiceProxies.ApplicationServiceProxy,
        ApiServiceProxies.MySironaServiceProxy,
        ApiServiceProxies.IdentificationServiceProxy,
        ApiServiceProxies.PersonChangeRequestServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.SponsorshipServiceProxy,
        ApiServiceProxies.DlaServiceProxy,
        ApiServiceProxies.DlaDaycareServiceProxy,
        ApiServiceProxies.DlaContactServiceProxy,
        ApiServiceProxies.DlaPlacesServiceProxy,
        ApiServiceProxies.DlaNutritionServiceProxy,
        ApiServiceProxies.DlaOpeningHoursServiceProxy,
        ApiServiceProxies.DlaPersonServiceProxy,
        ApiServiceProxies.DlaPersonEmploymentServiceProxy,
        ApiServiceProxies.DlaPersonDocumentServiceProxy,
        ApiServiceProxies.DlaDocumentServiceProxy,
        ApiServiceProxies.DlaSponsorshipServiceProxy,
        ApiServiceProxies.ProfessionRequirementServiceProxy,
        ApiServiceProxies.ProfessionRegisterAffiliationServiceProxy,
        ApiServiceProxies.ProfessionRequirementGroupServiceProxy,
        ApiServiceProxies.PersonProfessionalLicenceServiceProxy,
        ApiServiceProxies.PersonProfessionalLicenceDocumentServiceProxy,
        ApiServiceProxies.EmploymentServiceProxy,
        ApiServiceProxies.EmploymentAddressServiceProxy,
        ApiServiceProxies.RestrictionServiceProxy,
        ApiServiceProxies.LiaStatusWizardStepServiceProxy,
        ApiServiceProxies.EhcStatusWizardStepServiceProxy,
        ApiServiceProxies.EhcPracticeConfigurationServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.PersonDossierServiceProxy,
        ApiServiceProxies.BusinessServiceProxy,
        ApiServiceProxies.BusinessChangeRequestServiceProxy,
        ApiServiceProxies.DiplomaServiceProxy,
        ApiServiceProxies.PostgraduateTitleServiceProxy,
        ApiServiceProxies.AcademicTitleServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: SironaHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
