import { MaintenancePageComponent } from './shared/maintenance/maintenance-page.component';
import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CantonSpecificsResolver } from '@app/shared/canton-specifics/canton-specifics.resolver';
import { AppConsts } from '@shared/AppConsts';
import { FirstLoginRouteGuard } from './shared/services/first-login-route.guard';
import { SavedRouteGuard } from '@app/shared/services/saved-route.guard';
import { CategoryFilterGuard } from '@app/shared/services/category-filter.guard';
import { CodesGuard } from '@app/shared/services/codes.guard';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard, CategoryFilterGuard, CodesGuard],
                canActivateChild: [AppRouteGuard],
                resolve: { cantonSpecifics: CantonSpecificsResolver },
                children: [
                    {
                        path: '', canActivate: [FirstLoginRouteGuard], pathMatch: 'full', children: []
                    },
                    {
                        path: 'context/:serviceCategory',
                        canActivate: [FirstLoginRouteGuard],
                        pathMatch: 'full',
                        children: []
                    },
                    {
                        path: 'my-sirona',
                        loadChildren: () => import('@app/my-sirona/my-sirona.module').then(m => m.MySironaModule), //Lazy load module
                        canActivate: [SavedRouteGuard],
                        data: { preload: true }
                    },
                    {
                        path: 'services',
                        loadChildren: () => import('@app/services/services.module').then(m => m.ServicesModule), //Lazy load module
                        canActivate: [SavedRouteGuard],
                        data: { preload: true }
                    },
                    {
                        path: 'sponsorship',
                        loadChildren: () => import('@app/sponsorship/sponsorship.module').then(m => m.SponsorshipModule), //Lazy load module
                        data: { preload: true }
                    },
                    {
                        path: 'lia',
                        children: [
                            {
                                path: ':caseId',
                                loadChildren: () => import('app/shared/lia/lia.module').then(m => m.LiaModule), //Lazy load module
                                        data: { preload: false }
                            },
                            { path: '', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full' },
                            { path: '**', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'dla',
                        children: [
                            {
                                path: ':caseId',
                                loadChildren: () => import('app/shared/dla/dla.module').then(m => m.DlaModule), //Lazy load module
                                        data: { preload: false }
                            },
                            { path: '', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full'},
                            { path: '**', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'ehc',
                        children: [
                            {
                                path: ':caseId',
                                loadChildren: () => import('app/shared/ehc/ehc.module').then(m => m.EhcModule), //Lazy load module
                                        data: { preload: false }
                            },
                            { path: '', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full' },
                            { path: '**', redirectTo: AppConsts.applicantStartUrl, pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'authority',
                        loadChildren: () => import('app/authority/authority.module').then(m => m.AuthorityModule), //Lazy load module
                        data: { preload: false }
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard]
                    },
                    { path: 'maintenance', component: MaintenancePageComponent },
                    { path: 'notifications', component: NotificationsComponent },
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
